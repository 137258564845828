import React from "react";

interface ComponentProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  title?: string;
  size?: "xs" | "s" | "m" | "l";
  children?: JSX.Element | JSX.Element[];
}
export const Modal: React.FC<ComponentProps> = ({
  children,
  open,
  setOpen,
  title,
  size,
}) => {
  const getSize = () => {
    switch (size) {
      case "xs":
        return "w-1/4";
      case "s":
        return "w-1/3";
      case "m":
        return "w-2/3";
      case "l":
        return "w-full";
      default:
        return "w-1/3";
    }
  };

  return (
    <>
      {open ? (
        <div
          id="defaultModal"
          aria-hidden="true"
          className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center w-full h-screen overflow-y-auto bg-gray-100 bg-opacity-50 md:inset-0 h-modal md:h-full"
        >
          <div
            className={`relative max-w-2xl  bg-white rounded min-h-32 md:${getSize()}`}
          >
            <div className="flex items-center justify-between gap-3 p-4 text-white bg-blue-900">
              <span className="text-2xl font-semibold">{title}</span>
              <button
                className="px-4 py-2 bg-blue-900 border border-white rounded"
                onClick={() => setOpen(false)}
              >
                Close
              </button>
            </div>
            <div className="p-5">{children}</div>
          </div>
        </div>
      ) : null}
    </>
  );
};
