import { SerializedError } from "@reduxjs/toolkit";
import { AlertCircle, CheckCircle2, Loader2 } from "lucide-react";
import React, { useEffect } from "react";
import { Button } from "../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { CustomError } from "../../../../services";

interface ConfirmModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  description?: string;
  onConfirm?: () => void;
  action?: Function; // For backward compatibility
  item?: string | object;
  setItem?: React.Dispatch<React.SetStateAction<any>>;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  error?: CustomError | SerializedError;
  size?: "xs" | "s" | "m" | "l"; // For backward compatibility
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  open,
  setOpen,
  title,
  description,
  onConfirm,
  action, // For backward compatibility
  item,
  setItem,
  isLoading,
  isSuccess,
  isError,
  error,
  size, // For backward compatibility
}) => {
  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        setOpen(false);
        setItem && setItem(null);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [isSuccess, setOpen, setItem]);

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    } else if (action) {
      action(item);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setItem && setItem(null);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          {description && <DialogDescription>{description}</DialogDescription>}
        </DialogHeader>
        <div className="flex flex-col items-center justify-center py-4">
          {isLoading && (
            <Loader2 className="w-8 h-8 animate-spin text-primary" />
          )}
          {isSuccess && <CheckCircle2 className="w-8 h-8 text-green-500" />}
          {isError && error && (
            <div className="text-center text-red-500">
              <AlertCircle className="w-8 h-8 mx-auto mb-2" />
              <h3 className="font-semibold">Error</h3>
              <p>
                {(error as CustomError).data?.message || "An error occurred"}
              </p>
            </div>
          )}
          {!isSuccess && !isLoading && !isError && (
            <p className="text-center">
              Are you sure you want to {title.toLowerCase()}?
            </p>
          )}
        </div>
        {!isSuccess && !isLoading && (
          <DialogFooter className="sm:justify-center">
            <Button
              variant="secondary"
              onClick={handleConfirm}
              disabled={isLoading}
            >
              Proceed
            </Button>
            <Button
              variant="outline"
              onClick={handleClose}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  );
};
