import { EventTicket } from "@/models/event-ticket.model";
import { Event } from "@/models/event.model";
import { Clipboard, Edit, Trash2 } from "lucide-react";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { ConfirmModal } from "../../../../components/common/Modals";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../../components/ui/accordion";
import { Badge } from "../../../../components/ui/badge";
import { Button } from "../../../../components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import { useLazyGetEventTicketOrdersQuery } from "../../../../services/event-ticket.api";
import { useDeleteEventTicketMutation } from "../../../../services/event.api";
import { AddEventTicketModal } from "./AddEventTicketModal";
import EventTicketConversations from "./EventTicketConversation";
import { EventTicketInclusionModal } from "./EventTicketInclusionModal";
import { UpdateEventTicketModal } from "./UpdateEventTicketModal";

interface ComponentProps {
  event: Event;
}

export const EventTickets: React.FC<ComponentProps> = ({ event }) => {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const [active, setActive] = React.useState("");
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const [openUpdateModal, setOpenUpdateModal] = React.useState<string | null>(
    null
  );
  const [openDelete, setOpenDelete] = React.useState(false);
  const [eventTicket, setEventTicket] = React.useState<EventTicket>();

  const [eventTicketOrders] = useLazyGetEventTicketOrdersQuery();

  const [
    deleteEventTicket,
    {
      isError: deleteEventTicketIsError,
      error: deleteEventTicketError,
      isLoading: deleteEventTicketIsLoading,
    },
  ] = useDeleteEventTicketMutation();

  if (deleteEventTicketIsLoading) return <div>Loading...</div>;

  return (
    <div className="">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-semibold">Event Tickets</h2>
        <Button onClick={() => setOpenAddModal(true)}>Add Ticket</Button>
      </div>

      {isDesktopOrLaptop ? (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>ID</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Description</TableHead>
              <TableHead>Type</TableHead>
              <TableHead>Quantity</TableHead>
              <TableHead>Sold</TableHead>
              <TableHead>Scanned</TableHead>
              <TableHead>Remaining</TableHead>
              <TableHead>Price</TableHead>
              <TableHead>Status</TableHead>
              <TableHead className="text-right">Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {event.eventTickets.map((eventTicket: EventTicket) => (
              <TableRow key={eventTicket.id}>
                <TableCell>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() =>
                      navigator.clipboard.writeText(eventTicket.id || "")
                    }
                  >
                    <Clipboard className="w-4 h-4 mr-2" />
                    Copy ID
                  </Button>
                </TableCell>
                <TableCell>{eventTicket.name}</TableCell>
                <TableCell>{eventTicket.description}</TableCell>
                <TableCell>{eventTicket.type}</TableCell>
                <TableCell>{eventTicket.quantity}</TableCell>
                <TableCell>{eventTicket._count?.tickets || 0}</TableCell>
                <TableCell>0</TableCell>
                <TableCell>{eventTicket.remainingQuantity || 0}</TableCell>
                <TableCell>{eventTicket.price}</TableCell>
                <TableCell>
                  <Badge
                    variant={eventTicket.published ? "default" : "destructive"}
                  >
                    {eventTicket.published ? "Active" : "Inactive"}
                  </Badge>
                </TableCell>
                <TableCell className="text-right">
                  <div className="flex justify-end space-x-2">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => eventTicketOrders(eventTicket.id)}
                    >
                      Order
                    </Button>
                    <EventTicketInclusionModal eventTicket={eventTicket} />
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setOpenUpdateModal(eventTicket.id || "")}
                    >
                      <Edit className="w-4 h-4" />
                    </Button>
                    <Button
                      variant="destructive"
                      size="sm"
                      onClick={() => {
                        setEventTicket(eventTicket);
                        setOpenDelete(true);
                      }}
                    >
                      <Trash2 className="w-4 h-4" />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Accordion type="single" collapsible className="w-full">
          {event.eventTickets.map((eventTicket: EventTicket) => (
            <AccordionItem value={eventTicket.id} key={eventTicket.id}>
              <AccordionTrigger>{eventTicket.name}</AccordionTrigger>
              <AccordionContent>
                <div className="space-y-2">
                  <div>
                    <strong>ID:</strong> {eventTicket.id}
                  </div>
                  <div>
                    <strong>Description:</strong> {eventTicket.description}
                  </div>
                  <div>
                    <strong>Type:</strong> {eventTicket.type}
                  </div>
                  <div>
                    <strong>Quantity:</strong> {eventTicket.quantity}
                  </div>
                  <div>
                    <strong>Sold:</strong> {eventTicket._count?.tickets || 0}
                  </div>
                  <div>
                    <strong>Scanned:</strong> 0
                  </div>
                  <div>
                    <strong>Remaining:</strong>{" "}
                    {eventTicket.remainingQuantity || 0}
                  </div>
                  <div>
                    <strong>Price:</strong> {eventTicket.price}
                  </div>
                  <div>
                    <strong>Status:</strong>
                    <Badge
                      variant={
                        eventTicket.published ? "default" : "destructive"
                      }
                    >
                      {eventTicket.published ? "Active" : "Inactive"}
                    </Badge>
                  </div>
                  <div className="flex pt-2 space-x-2">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => eventTicketOrders(eventTicket.id)}
                    >
                      Order
                    </Button>
                    <EventTicketInclusionModal eventTicket={eventTicket} />
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setOpenUpdateModal(eventTicket.id || "")}
                    >
                      <Edit className="w-4 h-4" />
                    </Button>
                    <Button
                      variant="destructive"
                      size="sm"
                      onClick={() => {
                        setEventTicket(eventTicket);
                        setOpenDelete(true);
                      }}
                    >
                      <Trash2 className="w-4 h-4" />
                    </Button>
                  </div>
                </div>
                <div className="mt-4">
                  {active === eventTicket.id ? (
                    <EventTicketConversations eventTicketId={eventTicket.id} />
                  ) : (
                    <Button onClick={() => setActive(eventTicket.id)}>
                      View Conversations
                    </Button>
                  )}
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      )}

      <AddEventTicketModal
        open={openAddModal}
        setOpen={setOpenAddModal}
        eventId={event.id}
      />

      {event.eventTickets.map((eventTicket: EventTicket) => (
        <UpdateEventTicketModal
          key={eventTicket.id}
          open={openUpdateModal === eventTicket.id}
          setOpen={(isOpen) =>
            setOpenUpdateModal(isOpen ? eventTicket.id : null)
          }
          eventId={event.id}
          eventTicket={eventTicket}
        />
      ))}

      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        title="Delete Event Ticket"
        description="Are you sure you want to delete this event ticket? This action cannot be undone."
        onConfirm={() => {
          if (eventTicket) {
            deleteEventTicket({
              eventId: event.id,
              eventTicketId: eventTicket.id || "",
            });
          }
        }}
      />
    </div>
  );
};

export default EventTickets;
