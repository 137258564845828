import React from "react";
import { Modal } from "../../../../components/common/Modals/Modal";
import { EventTicket } from "../../../../models/event-ticket.model";
import { useUpdateEventTicketMutation } from "../../../../services/event.api";
import { EventTicketForm } from "./EventTicketForm";

interface ComponentProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  eventId: string;
  eventTicket: EventTicket;
}

export const UpdateEventTicketModal: React.FC<ComponentProps> = ({
  eventId,
  open,
  setOpen,
  eventTicket,
}) => {
  const [updateEventTicket, { isError, error, isLoading, isSuccess }] =
    useUpdateEventTicketMutation();

  React.useEffect(() => {
    if (isSuccess) setOpen(false);
  }, [isSuccess, setOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} setOpen={handleClose} title="Update Ticket" size="m">
      <EventTicketForm
        eventId={eventId}
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onSubmit={updateEventTicket}
        error={error}
        eventTicket={eventTicket}
        disabledInputs={["quantity"]}
        buttonText="Update Event Tickets"
      />
    </Modal>
  );
};

export default UpdateEventTicketModal;
